import { JsonViewer } from '@textea/json-viewer'
import React, { useState } from 'react'
import { createRoot } from 'react-dom/client'

import {
  CreateTransportServiceHooksProps,
  QueryProvider,
  installTransportServiceHooks,
  useTransportsSearchScopedByOrder,
} from '@schuettflix/app-transport'
import { Button, MessageBox, TextField } from '@schuettflix/react-components'
import { createModule } from '@schuettflix/util-app-wrapper'
import { DemoEnvironment } from '@schuettflix/util-demo-environment'

const InternalWidgetTransportsSearchScopedByOrder = () => {
  const [orderId, setOrderId] = useState<string | undefined>(undefined)
  const [temporaryOrderId, setTemporaryOrderId] = useState<string | undefined>(undefined)
  const { data, isLoading, isError, error } = useTransportsSearchScopedByOrder({ orderId })

  return (
    <section>
      <h1 className="font-headline-xl-strong mb-8">
        <span className="text-brand italic uppercase">Hooks</span> Transports Search Scoped By Order
      </h1>

      <form className="space-y-4">
        <fieldset className="flex gap-2">
          <TextField
            className="w-full"
            label="Order ID"
            value={temporaryOrderId || ''}
            onChange={e => setTemporaryOrderId(e.target.value || undefined)}
            disabled={isLoading}
          />
          <Button
            label="Submit"
            disabled={isLoading || !temporaryOrderId}
            onClick={() => setOrderId(temporaryOrderId)}
          />
        </fieldset>

        {!!isError && <MessageBox type="error">{error}</MessageBox>}
        {!!data && (
          <div className="card card--sm card--rounded">
            <JsonViewer value={data} />
          </div>
        )}
      </form>
    </section>
  )
}

// eslint-disable-next-line react-refresh/only-export-components
export const widgetTransportsSearchScopedByOrder = createModule<CreateTransportServiceHooksProps>({
  name: 'widget-transports-scoped-by-order',
  onInstall: options => {
    installTransportServiceHooks(options)
  },
  onMount: mountElement => {
    const root = createRoot(mountElement)

    root.render(
      <React.StrictMode>
        <QueryProvider>
          <InternalWidgetTransportsSearchScopedByOrder />
        </QueryProvider>
      </React.StrictMode>
    )
    return () => {
      root.unmount()
    }
  },
})

const { createReactComponent } = DemoEnvironment

export const WidgetTransportsSearchScopedByOrder = createReactComponent(widgetTransportsSearchScopedByOrder)
